import React, { useState } from "react";
import Input from "../common/form/Input";
import Button from "../common/Button";
import { twMerge } from "tailwind-merge";

const SoilTestResult = React.forwardRef(
  ({ formik, fields, numberLayer, showNextLayer = true }, ref) => {
    const [currentLayer, setCurrentLayer] = useState(1);

    const getCurrentLayer = () => {
      return currentLayer;
    };

    const validateCurrentLayer = (specificLayer) => {
      let isValid = true;
      let fieldsError = [];
      fields.forEach((row) => {
        row.forEach((field) => {
          const key = `soil_layer_${specificLayer || currentLayer}_${field.key}`;
          const isNitrateOrOrganicMatter = (field.key === "nitrate" || field.key === "organic_matter"); 

          if (
            (field.required || isNitrateOrOrganicMatter)
            && (!formik.values[key] || formik.values[key] === "" || formik.values[key] === null)
          ) {
            isValid = false;
            fieldsError.push(key);
            formik.setFieldTouched(key, true, false);
          }
        });
      });
      if (fieldsError.length) {
        formik.setErrors(
          fieldsError.reduce(
            (acc, key) => ({ ...acc, [key]: "Field is required" }),
            {}
          )
        );
      }
      return isValid;
    };

    const nextLayer = () => {
      let isValid = true;
      if (!validateCurrentLayer()) {
        isValid = false;
      }
      if (isValid) {
        if (currentLayer < numberLayer) {
          setCurrentLayer(currentLayer + 1);
          if (!formik.values[`soil_layer_${currentLayer + 1}_nitrate`]) {
            formik.setFieldError(
              `soil_layer_${currentLayer + 1}_nitrate`,
              "Field is required"
            );
            // formik.setFieldTouched(`soil_layer_${currentLayer + 1}_nitrate`);
            isValid = false;
          }
        }
      }
      return isValid;
    };

    const initialValidation = () => {
      if (!formik.values.soil_layer_1_nitrate) {
        formik.setFieldError("soil_layer_1_nitrate", "Field is required");
        formik.setFieldError(
          "soil_layer_1_organic_matter",
          "Field is required"
        );
      }
    };

    const isValid = () => {
      let isValid = 0;
      for (let i = 1; i <= numberLayer; i++) {
        if (validateCurrentLayer(i) === false) {
          return i;
        }
      }
      return isValid;
    };

    const prevLayer = () => {
      setCurrentLayer(currentLayer - 1);
    };

    const selectLayer = (index) => {
      setCurrentLayer(index);
    };

    React.useImperativeHandle(ref, () => ({
      nextLayer,
      prevLayer,
      initialValidation,
      selectLayer,
      getCurrentLayer,
      validateCurrentLayer,
      isValid,
    }));

    return Array(numberLayer)
      .fill("")
      .map((item, layerIndex) => (
        <div
          key={`layer_${layerIndex}`}
          className={twMerge(
            "invisible opacity-0",
            currentLayer === layerIndex + 1
              ? "visible opacity-100 content_active"
              : "h-0"
          )}
        >
          <div className="font-bold text-center mb-5">
            Layer {layerIndex + 1} (
            {formik.values[`soil_layer_${layerIndex + 1}_top_depth`]} to{" "}
            {formik.values[`soil_layer_${layerIndex + 1}_bottom_depth`]}) Test
            Results:
          </div>
          {fields.map((row, rowIndex) => {
            const isLastRow = rowIndex === fields.length - 1;
            return (
              <div
                key={`row_${rowIndex}`}
                className={`flex justify-between gap-5 mb-3 ${
                  isLastRow ? "items-end" : "items-start"
                }`}
              >
                {isLastRow && showNextLayer && (
                  <Button
                    type="button"
                    className={`px-3 ${currentLayer === 1 ? "invisible" : ""}`}
                    onClick={prevLayer}
                  >
                    Prev Layer
                  </Button>
                )}
                {row.map((field, index) => {
                  const { key, number, suffix, label } = field;
                  const formKey = `soil_layer_${currentLayer}_${key}`;
                  return (
                    <div key={`${key}_${index}`} className="flex-1">
                      <Input
                        key={formKey}
                        name={formKey}
                        label={label}
                        suffix={suffix}
                        type={number ? "number" : "text"}
                        onChange={(e) => {
                          formik.setFieldValue(
                            formKey,
                            number && e.target.value
                              ? parseFloat(e.target.value)
                              : e.target.value
                          );
                          formik.setFieldTouched(key);
                        }}
                        value={formik.values[formKey]}
                      />
                    </div>
                  );
                })}
                {isLastRow && showNextLayer && (
                  <Button
                    type="button"
                    className={`px-3 ${
                      currentLayer < numberLayer ? "" : "invisible"
                    }`}
                    onClick={nextLayer}
                  >
                    Next Layer
                  </Button>
                )}
              </div>
            );
          })}
        </div>
      ));
  }
);

export default SoilTestResult;
