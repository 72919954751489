import React, { useRef, useState, useEffect, useMemo } from "react";
import { steps } from "../soil/configs";
import { FormikProvider } from "formik";
import Button from "../common/Button";
import { twMerge } from "tailwind-merge";
import Input from "../common/form/Input";
import Toggle from "../common/Toggle";
import DatePicker from "../common/form/DatePicker";
import SoilLayer from "../soil/SoilLayer";
import SoilTestResult from "../soil/SoilTestResult";
import { ChevronLeftIcon } from "@heroicons/react/20/solid";
import Select from "../common/form/Select";
import ConfirmModal from "../common/ConfirmModal";
import { useFormik } from "formik";
import toast from "react-hot-toast";
import { SoilSchema } from "../../utils/validation";
import request from "../../utils/fetch";


const SoilReportFormContent = ({
  isLoading,
  renderMap,
  initNumberLayer = 1,
  backToPrevReport,
  error,
  setError,
  activeReportStep,
  setReportStep,
  soilReportData,
  tractAcres,
  setLoading,
  reportId,
  refreshData,
  activeReport,
  setActiveReport,
  refreshSoilsData,
  activeStep,
  setActiveStep,
  activeLayerStep,
  setActiveLayerStep,
}) => {

  const [numberLayer, setNumberLayer] = useState((soilReportData?.soil_layers?.length || 1) || initNumberLayer);
  const [soilId, setSoilId] = useState(soilReportData._id);
  const [nextTestResultLayer, setNextTestResultLayer] = useState();
  const layerRef = useRef();
  const [currentStep, setCurrentStep] = useState(steps[activeStep]);

  useEffect(() => {
    setCurrentStep(steps[activeStep]);
  }, []);

  useEffect(() => {
    if (soilReportData && soilReportData._id) {
      setSoilId(soilReportData._id);
    }
  }, [soilReportData._id]);

  const getCurrentLayerData = (layerNum) => {
    const fieldsForLayer = [
      "top_depth", "bottom_depth", "calcium", "cec", "magnesium",
      "nitrate", "organic_matter", "ph", "phosphate", "potassium",
      "sodium", "soluble_salts", "sulfate"
    ];

    const layerData = {};
    fieldsForLayer.forEach((field) => {
      const key = `soil_layer_${layerNum}_${field}`;
      if (formik.values[key] !== undefined) {
        layerData[field] = formik.values[key];
      }
    });

    return layerData;
  };

  const savePrimarySoilData = async () => {
    try {
      const cleanedValues = cleanTopLevelLayerFields(formik.values);
      const payload = {
        ...cleanedValues,
        soil_layers: Array.from({ length: numberLayer }).map((_, index) => {
          const layerNum = index + 1;
          return {
            layer_num: layerNum,
            top_depth: formik.values[`soil_layer_${layerNum}_top_depth`] || 0,
            bottom_depth: formik.values[`soil_layer_${layerNum}_bottom_depth`] || 0,
          };
        }),
      };

      const isNewEntry = !soilId;

      const response = await request({
        method: isNewEntry ? "post" : "patch",
        url: `/reports/${reportId}/soils/${isNewEntry ? "" : soilId}`,
        data: payload,
      });

      await refreshData();

      if (response) {
        if (activeReport === "new") {
          setSoilId(response);
          setActiveReport(response);

          // FIXME: This takes us back to the map, but should go to the test results.
        }
        toast.success("Soil data saved successfully.");
        return true;
      }

      toast.error("Unexpected response while saving soil data.");
      return false;

    } catch (error) {
      toast.error("An error occurred while saving soil data.");
      return false;
    }
  };

  const saveTestResults = async (layerNum) => {
    try {
      const layerData = getCurrentLayerData(layerNum);

      await request({
        method: "patch",
        url: `/reports/${reportId}/soils/${soilId}/layer/${layerNum}`,
        data: layerData,
      });
      refreshData();
      toast.success(`Layer ${layerNum} saved successfully`);
      return true;
    } catch (error) {
      toast.error(`Failed to save layer ${layerNum}`);
      return false;
    }
  };

  useEffect(() => {
    if (soilReportData?.soil_layers?.length) {
      setNumberLayer(soilReportData.soil_layers.length);
    }
  }, [soilReportData]);

  const formatDateForInput = (dateString) => {
    if (!dateString) return null;

    try {
      return new Date(dateString).toISOString();
    } catch {
      return dateString;
    }
  };

  const cleanTopLevelLayerFields = (values) => {
    const cleanedValues = { ...values };

    Object.keys(cleanedValues).forEach((key) => {
      if (/^soil_layer_\d+_.+/.test(key)) {
        delete cleanedValues[key];
      }
    });

    delete cleanedValues.soil_location_id;
    delete cleanedValues._id;

    return cleanedValues;
  };

  const initialValues = useMemo(() => {
    if (!soilReportData) {
      return {
        sample_date: formatDateForInput(new Date()),
        lab_date: formatDateForInput(new Date()),
        sample_id: `Sample ${new Date().getFullYear()}`,
        acres_represented: tractAcres || 0,
        composite_sample: false,
        soil_layers: [],
      };
    }

    const formattedLayers = (soilReportData.soil_layers || []).reduce((acc, layer) => {
      const layerPrefix = `soil_layer_${layer.layer_num}_`;
      acc[`${layerPrefix}top_depth`] = layer.top_depth;
      acc[`${layerPrefix}bottom_depth`] = layer.bottom_depth;
      acc[`${layerPrefix}calcium`] = layer.calcium;
      acc[`${layerPrefix}magnesium`] = layer.magnesium;
      acc[`${layerPrefix}nitrate`] = layer.nitrate;
      acc[`${layerPrefix}organic_matter`] = layer.organic_matter;
      acc[`${layerPrefix}ph`] = layer.ph;
      acc[`${layerPrefix}phosphate`] = layer.phosphate;
      acc[`${layerPrefix}potassium`] = layer.potassium;
      acc[`${layerPrefix}sodium`] = layer.sodium;
      acc[`${layerPrefix}soluble_salts`] = layer.soluble_salts;
      acc[`${layerPrefix}sulfate`] = layer.sulfate;
      acc[`${layerPrefix}cec`] = layer.cec;
      return acc;
    }, {});

    return {
      ...soilReportData,
      ...formattedLayers,
    };
  }, [soilReportData, tractAcres]);

  const formik = useFormik({
    initialValues,
    validationSchema: SoilSchema,
    enableReinitialize: !soilId,
    onSubmit: async (values) => {
      try {
        setLoading(true);

        const soilLayers = Array.from({ length: numberLayer }).map((_, index) => {
          const layerNum = index + 1;
          return {
            layer_num: layerNum,
            top_depth: values[`soil_layer_${layerNum}_top_depth`] || 0,
            bottom_depth: values[`soil_layer_${layerNum}_bottom_depth`] || 0,
            calcium: values[`soil_layer_${layerNum}_calcium`] || 0,
            cec: values[`soil_layer_${layerNum}_cec`] || 0,
            magnesium: values[`soil_layer_${layerNum}_magnesium`] || 0,
            nitrate: values[`soil_layer_${layerNum}_nitrate`] || 0,
            organic_matter: values[`soil_layer_${layerNum}_organic_matter`] || 0,
            ph: values[`soil_layer_${layerNum}_ph`] || 0,
            phosphate: values[`soil_layer_${layerNum}_phosphate`] || 0,
            potassium: values[`soil_layer_${layerNum}_potassium`] || 0,
            sodium: values[`soil_layer_${layerNum}_sodium`] || 0,
            soluble_salts: values[`soil_layer_${layerNum}_soluble_salts`] || 0,
            sulfate: values[`soil_layer_${layerNum}_sulfate`] || 0,
          };
        });

        const isNewEntry = !soilId;
        const cleanedValues = cleanTopLevelLayerFields(values);

        const finalPayload = {
          ...cleanedValues,
          soil_layers: soilLayers,
        };

        await request({
          method: isNewEntry ? "post" : "patch",
          url: `/reports/${reportId}/soils/${isNewEntry ? "" : soilReportData._id}`,
          data: finalPayload,
        });

        toast.success(`Soil ${isNewEntry ? "added" : "updated"} successfully`);


      if (activeLayerStep < numberLayer) {
        setActiveLayerStep(activeLayerStep + 1);
      } else if (activeStep < steps.length - 1) {
        setActiveStep(activeStep + 1);
      } else {
        setError(null);
      }

        refreshData();
        setError(null);
      } catch (e) {
        toast.error(e.response?.data?.message || e.message);
        setError(e.message);
      } finally {
        setLoading(false);
      }
    },
  });

  const renderItem = (
    {
      label,
      key,
      suffix,
      bool,
      textarea,
      number,
      date,
      comment,
      elementClassName,
      soilLayer,
      dropdown,
      options,
    },
    index
  ) => {
    const labelToShow = label || key.split("_").join(" ");

    const inputComment = comment && (
      <div className="text-sm text-gray-500">{comment}</div>
    );

    if (bool) {
      return (
        <div
          key={`${key}_${index}`}
          className={elementClassName || "col-span-1"}
        >
          <Toggle
            key={key}
            value={formik.values[key]}
            label={labelToShow}
            onChange={(value) => formik.setFieldValue(key, value)}
          />
          {inputComment}
        </div>
      );
    }
    if (date) {
      return (
        <div
          key={`${key}_${index}`}
          className={elementClassName || "col-span-1"}
        >
          <DatePicker
            label={labelToShow}
            value={formik.values[key]}
            onChange={(e) => {
              formik.setFieldValue(key, e.target.value);
            }}
          />
        </div>
      );
    }
    if (soilLayer) {
      return (
        <SoilLayer
          key={`${key}_${index}`}
          formik={formik}
          numberLayer={numberLayer}
          setNumberLayer={setNumberLayer}
          error={error}
        />
      );
    }
    if (dropdown) {
      return (
        <Select
          id={key}
          name={key}
          label={labelToShow}
          extraOptions={options}
          onChange={(option) => {
            formik.setFieldValue(key, option);
          }}
          value={formik.values[key]}
        />
      );
    }
    return (
      <div key={`${key}_${index}`} className={elementClassName || "col-span-1"}>
        <Input
          name={key}
          label={labelToShow}
          textarea={textarea}
          suffix={suffix}
          type={number ? "number" : "text"}
          onChange={(e) => {
            formik.setFieldValue(
              key,
              number && e.target.value
                ? parseFloat(e.target.value)
                : e.target.value
            );
            formik.setFieldTouched(key);
          }}
        />
        {inputComment}
      </div>
    );
  };

  const handleNextClick = async (e) => {
    e.preventDefault();

    if (activeStep >= steps.length - 1) {
      toast.success("All steps completed successfully!");
      setReportStep(activeReportStep + 1);
      return
    }

    if (activeStep === 1) {
      const success = await savePrimarySoilData();
      if (!success) {
        setError("Failed to save soil data.");
        return;
      }
      setActiveStep(activeStep + 1);
      return;
    }

    if (currentStep.type && currentStep.type === "test_result") {

      const layer = layerRef;
      const currentLayer = layer.current.getCurrentLayer();
      const isValid = layer.current.validateCurrentLayer();

      if (!isValid) {
        setError(`Please fix errors in layer ${currentLayer} before proceeding.`);
        return;
      }

      const saveSuccess = await saveTestResults(currentLayer);
      if (!saveSuccess) {
        setError(`Failed to save results for layer ${currentLayer}`);
        return;
      }

      if (currentLayer < numberLayer) {
        layer.current.nextLayer();

      } else {
        setActiveStep(activeStep + 1);
      }
    } else {
      setActiveStep(activeStep + 1);
    }
  };

  const previousStep = (e) => {
    e.preventDefault();
    if (currentStep.type === "test_result" && layerRef.current.getCurrentLayer() > 1) {
      layerRef.current.prevLayer();
    } else {
      if (activeStep === 0) {
        backToPrevReport();
      } else {
        setActiveStep(activeStep === 3 ? activeStep - 2 : activeStep - 1);
        //setActiveStep(activeStep -1)
      }
    }
    setError(null);
  };

  return (
    <div className="p-2 flex relative">
      <div className="w-full bg-white dark:bg-gray-600 rounded-lg">
        {activeStep === 1 && (
          <div className="text-lg font-bold">Add Soil Sample Information (Layer {activeLayerStep} of {numberLayer})</div>
        )}
        <FormikProvider value={formik}>
          <form onSubmit={formik.handleSubmit}>
            <div className="p-4">
              {steps.map((step, stepIdx) => {
                if (step.type === "test_result") {
                  return (
                    <div
                      key={`test_result_${stepIdx}`}
                      className={activeStep === stepIdx ? "visible" : "hidden"}
                    >
                      <div className="flex items-center">
                        <span>Soil Layer Results:</span>
                        <div className="flex items-center gap-2 ml-5 flex-wrap">
                          {Array(numberLayer)
                            .fill("")
                            .map((_, layerIndex) => {
                              const layerNum = layerIndex + 1;
                              return (
                                <span key={layerNum}>
                                  Layer {layerNum} (
                                  {formik.values[`soil_layer_${layerNum}_top_depth`] || 0} to
                                  {formik.values[`soil_layer_${layerNum}_bottom_depth`] || 0}
                                )
                              </span>
                            );
                          })}
                        </div>
                      </div>
                      <div className={twMerge("mt-5", step.className)}>
                        <SoilTestResult
                          ref={layerRef}
                          formik={formik}
                          fields={step.fields}
                          numberLayer={numberLayer}
                          showNextLayer={false}
                        />
                      </div>
                    </div>
                  );
                } else if (step.type === "map") {
                  return (
                    <div
                      key={`map_${stepIdx}`}
                      className={activeStep === stepIdx ? "visible" : "hidden"}
                    >
                      <div className="text-lg">Soil Sample Data</div>
                      <div>
                        Click on a soil sample location in the map to start the process or
                        press “Add Sample Location” to add a new location for your soil sample.
                      </div>
                      <div className="w-full h-[472px] mt-5">{renderMap()}</div>
                    </div>
                  );
                // } else if (step.type === "submit") {
                //   return (
                //     <div
                //       key={stepIdx}
                //       className={twMerge(
                //         "flex flex-col justify-center items-center",
                //         activeStep === stepIdx ? "visible" : "hidden",
                //         step.className
                //       )}
                //     >
                //       <Button
                //         type="submit"
                //         disabled={isLoading}
                //         loading={isLoading}
                //         className="my-5 w-fit float-right"
                //       >
                //         Next
                //       </Button>
                //     </div>
                //   );
                // }
              } else if (step.type === "add_soil") {
                return (
                  <div
                    key={`add_soil_${stepIdx}`}
                    className={activeStep === stepIdx ? "visible" : "hidden"}
                  >
                    <div className="text-lg font-bold mb-4">
                      Would you like to add another soil sample?
                    </div>
                    <Button
                      onClick={() => {
                        if (!soilReportData || soilReportData._id) {
                          setActiveReport("new");
                          setActiveStep(0);
                        }
                      }}
                      disabled={soilReportData && !soilReportData._id}
                    >
                      Add Soil
                    </Button>
                  </div>
                );
                } else {
                return (
                  <div
                    key={`soil_item_${stepIdx}`}
                    className={twMerge(
                      "grid grid-cols-1 md:grid-cols-2 gap-5",
                      activeStep === stepIdx ||
                        (activeStep === 1 && stepIdx === 2)
                        ? "visible mt-5"
                        : "hidden",
                      step.className
                    )}
                  >
                    {(step.fields || []).map((item, index) => renderItem(item, index))}
                  </div>
                );
              }})}
              <div className="flex items-center justify-between mt-5">
                <ChevronLeftIcon
                  width={40}
                  height={40}
                  className="cursor-pointer"
                  onClick={previousStep}
                />
                <Button
                  type={"button"}
                  className="w-fit z-100"
                  onClick={handleNextClick}
                >
                  Next
                </Button>
              </div>
              <div
                className={twMerge(
                  "mt-2 text-red-500 invisible opacity-0 text-end",
                  error ? "visible opacity-100 content_active" : "h-0"
                )}
              >
                {error}
              </div>
            </div>
          </form>
        </FormikProvider>
      </div>
      <ConfirmModal
        isOpen={!!nextTestResultLayer}
        toggle={() => {
          setNextTestResultLayer(null);
        }}
        message="The current layer has invalid fields. Do you still want to continue to the other layer?"
        onConfirm={() => {
          layerRef.current.selectLayer(nextTestResultLayer);
          setTimeout(() => {
            setActiveLayerStep(nextTestResultLayer);
            setNextTestResultLayer(null);
          }, 200);
        }}
        warning={true}
      />
    </div>
  );
};

export default SoilReportFormContent;
