import React, { useCallback, useEffect, useMemo, useState } from "react";
import { useNavigate } from "react-router-dom";
import {
  EnvelopeIcon,
  TrashIcon,
  EnvelopeOpenIcon,
  ExclamationCircleIcon,
} from "@heroicons/react/24/solid";
import HighLight from "../highlight";
import { NOTIFICATIONS } from "../../data/home";
import TableData from "../table";
import useFetch from "../../hooks/useFetch";
import Loading from "../common/Loading";
import moment from "moment";
import MessageDetailModal from "./MessageDetailModal";
import request from "../../utils/fetch";
import ConfirmModal from "../common/ConfirmModal";
import toast from "react-hot-toast";
import { useUserContext } from "../../context/user";
import { useMapRender } from "../../hooks/map/useMapRender";
const LIMIT = 10;

const getTractDataForMap = (data) => {
  return (data || []).map((item) => ({
    ...item,
    Category: item.category,
    Alias: item.label,
    _id: item.label,
  }));
};

const getWellDataForMap = (data) => {
  return (data || []).map((item) => ({
    ...item,
    _id: item.well_id,
  }));
};

const HomePage = () => {
  const [page, setPage] = useState(1);
  const [activeMessage, setActiveMessage] = useState();
  const [messageToDelete, setMessageToDelete] = useState();
  const [canLoadMore, setCanLoadMore] = useState(
    LIMIT * page < NOTIFICATIONS.length
  );
  const [isLoading, setShowLoading] = useState(true);
  const { data } = useFetch("/home");
  const { data: messages, refresh: refreshMessages } = useFetch("/messages");
  const [, { fetchNavbar }] = useUserContext();
  const navigate = useNavigate();
  useEffect(() => {
    if (data) {
      setShowLoading(false);
    }
  }, [data]);
  const handleScrollBottom = () => {
    if (LIMIT * page < NOTIFICATIONS.length) {
      setTimeout(() => {
        setPage(page + 1);
      }, 3000);
    } else {
      setCanLoadMore(false);
    }
  };
  const handleMessageClick = useCallback(
    async (item) => {
      setActiveMessage(item);
      try {
        await request({
          url: `/messages/${item._id}/SetRead`,
          method: "put",
        });
        fetchNavbar();
        refreshMessages();
      } catch (e) {
        toast.error(e.response?.data?.message || e.message);
      }
    },
    [refreshMessages, fetchNavbar]
  );
  const handleDeleteMessage = useCallback(async () => {
    try {
      await request({
        url: `/messages/${messageToDelete._id}`,
        method: "delete",
      });
      refreshMessages();
    } catch (e) {
      toast.error(e.response?.data?.message || e.message);
    } finally {
      setMessageToDelete(null);
    }
  }, [refreshMessages, messageToDelete]);
  const handleIconClick = useCallback(
    (type, id) => {
      switch (type) {
        case "Tract":
          navigate(`/tracts?id=${id}`);
          break;
        case "Well":
          navigate(`/wells?id=${id}`);
          break;
        case "Flowmeter":
          navigate(`/flowmeters?id=${id}`);
          break;
        case "Chemigation":
          navigate(`/chemigations?id=${id}`);
          break;
        default:
          break;
      }
    },
    [navigate]
  );
  const highlight = useMemo(() => {
    if (data) {
      return [
        {
          title: "Total Tract Acres",
          value: data.total_acres,
        },
        {
          title: "Reported Corn Yield",
          value: "225 bu / ac",
          percentage: 7,
        },
        {
          title: "Total Pumping",
          value: Number(data.total_pumping?.["2023"] || 0).toFixed(2),
          percentage: Number(
            (data.total_pumping?.["2023"] / data.total_pumping?.["2022"] || 0) *
              100
          ).toFixed(0),
        },
      ];
    }
    return [];
  }, [data]);
  const notifications = (
    <div className="rounded-lg bg-white dark:bg-gray-900 dark:text-white w-full mt-5 p-3">
      <div className="font-bold text-2xl">Messages</div>
      <TableData
        placeholder="No Messages"
        data={messages || []}
        page={page}
        headerItems={
          <>
            <div className="w-1/6 text-center">DATE</div>
            <div className="w-1/2">MESSAGE TITLE</div>
            <div className="w-1/6 text-center">ACTIONS</div>
          </>
        }
        renderRow={(item, index) => {
          const Envelop = item.read ? EnvelopeOpenIcon : EnvelopeIcon;
          return (
            <div
              className={`flex w-full py-4 cursor-pointer ${
                !item.read ? "font-bold" : ""
              }`}
              onClick={() => handleMessageClick(item)}
            >
              <div className="text-center w-1/6">
                {moment(item.message_date).format("MM-DD-YYYY")}
              </div>
              <div className="w-1/2">
                {item.message_type === "High" ||
                item.message_type === "Important" ||
                item.message_type === "Urgent" ? (
                  <ExclamationCircleIcon className="w-5 h-5 text-red-500 mr-2" />
                ) : null}
                {item.message_title}
              </div>
              <div className="flex items-center justify-center gap-x-2 w-1/6">
                <Envelop className="w-5 h-5 text-primary" />
                <TrashIcon
                  className="w-5 h-5 text-primary"
                  onClick={(e) => {
                    e.stopPropagation();
                    setMessageToDelete(item);
                  }}
                />
              </div>
            </div>
          );
        }}
      />
    </div>
  );
  const { renderMap } = useMapRender({
    tracts: getTractDataForMap(data?.tracts),
    wells: getWellDataForMap(data?.wells),
    meters: (data?.meters || []).map((item) => ({
      ...item,
      FM_ID: item.meter_id,
      _id: item.meter_id,
    })),
    chemigations: (data?.chemigations || []).map((item) => ({
      ...item,
      Permit_No: item.chem_id,
      _id: item.chem_id,
    })),
    soils: data?.soils,
    extraMapProps: { onIconClick: handleIconClick, expandable: true },
  });
  if (isLoading) {
    return <Loading />;
  }
  return (
    <div className="w-full min-h-screen">
      <h2 className="font-bold text-2xl dark:text-white">Overview</h2>
      {
        <div className="my-6 flex justify-center gap-x-4">
          {highlight.map((item, index) => (
            <HighLight key={index} data={item} />
          ))}
        </div>
      }
      <div className="w-3/5 aspect-video mx-auto">{renderMap()}</div>
      {notifications}
      <MessageDetailModal
        open={!!activeMessage}
        data={activeMessage}
        close={() => setActiveMessage(null)}
      />
      <ConfirmModal
        isOpen={!!messageToDelete}
        toggle={() => setMessageToDelete(null)}
        title="Delete"
        message="Are you sure you want to delete this message?"
        onConfirm={handleDeleteMessage}
      />
    </div>
  );
};
export default HomePage;
