import React, { useState, useCallback, useMemo, useEffect } from "react";
import toast from "react-hot-toast";
import request from "../../utils/fetch";
import useFetch from "../../hooks/useFetch";
import SoilReportFormContent from "./SoilReportFormContent";
import { useMapRender } from "../../hooks/map/useMapRender";
import pointsWithinPolygon from "@turf/points-within-polygon";
import convertToFeatureCollection from "../../utils/convertToFeatureCollection";
import { XMarkIcon } from "@heroicons/react/20/solid";
import ConfirmModal from "../common/ConfirmModal";

const SoilReportForm = ({
  setReportStep,
  activeReportStep,
  soilReportData,
  reportYear,
  tract,
  tractAcres,
  reportId,
  refreshData,
  soilsData,
  refreshSoilsData,
  activeReport,
  setActiveReport,
  activeStep,
  setActiveStep,
  activeLayerStep,
  setActiveLayerStep,
  onSoilStatusChange,
  setNewSoilInProgress
}) => {
  const [isLoading, setLoading] = useState(true);
  const [selectedSoil, setSelectedSoil] = useState();
  const [error, setError] = useState();

  useEffect(() => {
    const isNewSoilInProgress = (activeReport === "new" && (!soilReportData || !soilReportData._id)) || ((activeStep === 1) || (activeStep === 2));
    if (onSoilStatusChange) {
      setNewSoilInProgress(isNewSoilInProgress);
    }
  }, [activeStep, activeReport, soilReportData, onSoilStatusChange]);

  const initialValues = useMemo(() => {
    if (!soilReportData) {
      return {
        sample_date: new Date().toISOString(),
        lab_date: new Date().toISOString(),
        soil_layers: [],
      };
    }

    const layerFields = {};
    soilReportData.soil_layers?.forEach((layer) => {
      const layerNum = layer.layer_num;
      layerFields[`soil_layer_${layerNum}_top_depth`] = layer.top_depth;
      layerFields[`soil_layer_${layerNum}_bottom_depth`] = layer.bottom_depth;
      layerFields[`soil_layer_${layerNum}_calcium`] = layer.calcium;
      layerFields[`soil_layer_${layerNum}_cec`] = layer.cec;
      layerFields[`soil_layer_${layerNum}_magnesium`] = layer.magnesium;
      layerFields[`soil_layer_${layerNum}_nitrate`] = layer.nitrate;
      layerFields[`soil_layer_${layerNum}_organic_matter`] = layer.organic_matter;
      layerFields[`soil_layer_${layerNum}_ph`] = layer.ph;
      layerFields[`soil_layer_${layerNum}_phosphate`] = layer.phosphate;
      layerFields[`soil_layer_${layerNum}_potassium`] = layer.potassium;
      layerFields[`soil_layer_${layerNum}_sodium`] = layer.sodium;
      layerFields[`soil_layer_${layerNum}_soluble_salts`] = layer.soluble_salts;
      layerFields[`soil_layer_${layerNum}_sulfate`] = layer.sulfate;
    });

    return {
      ...soilReportData,
      ...layerFields,
    };
  }, [soilReportData]);

  const handleSoilClick = useCallback(
    (type, id) => {
      const newSelectedSoil = (soilsData?.soils || []).find(
        (item) => item._id === id
      );
      if (newSelectedSoil) {
        setSelectedSoil(newSelectedSoil);
      }
    },
    [soilsData?.soils]
  );

  const filteredSoils = useMemo(() => {
    if (!soilsData?.soils || !tract) return [];
    const soilsGeo = convertToFeatureCollection(soilsData.soils, "location_id");
    const tractPolygon = convertToFeatureCollection(
      [tract].map((item) => ({
        ...item,
        Alias: item.Alias || item.Cert_ID || item.tract_id,
      })),
      "Alias",
      ["Category"],
      "Tract"
    );
    const filteredGeo = soilsGeo.features.filter(
      (soil) =>
        pointsWithinPolygon(soil, tractPolygon.features[0])?.features.length
    );

    return soilsData.soils.filter((soil) =>
      filteredGeo.find((geo) => geo.properties.id === soil._id)
    );
  }, [soilsData?.soils, tract]);

  const { renderMap } = useMapRender({
    tracts: [tract],
    soils: filteredSoils,
    selectedItem: selectedSoil,
    mapComponent: "soil",
    extraMapProps: {
      iconSize: 1.5,
      onIconClick: handleSoilClick,
      showSoilLocationButton: true,
      refreshSoilData: refreshSoilsData,
      bboxPadding: 100,
      fetchedSoils: !!soilsData?.soils,
      bboxMaxZoom: 15.5,
    },
  });

  const initNumberLayer = useMemo(() => {
    let numLayer = 1;
    if (soilReportData) {
      for (const key in soilReportData) {
        const match = key.match(/^soil_layer_(\d+)_/);
        if (match) {
          const layerNumber = parseInt(match[1], 10);
          if (layerNumber > numLayer) {
            numLayer = layerNumber;
          }
        }
      }
    }
    return numLayer;
  }, [soilReportData]);

  return (
    <SoilReportFormContent
      soilReportData={initialValues}
      isLoading={isLoading}
      renderMap={renderMap}
      initNumberLayer={initNumberLayer}
      backToPrevReport={() => setReportStep(activeReportStep - 1)}
      error={error}
      setError={setError}
      setReportStep={setReportStep}
      activeReportStep={activeReportStep}
      tractAcres={tractAcres}
      setLoading={setLoading}
      reportId={reportId}
      refreshData={refreshData}
      refreshSoilsData={refreshSoilsData}
      isNewEntry={!soilReportData || !soilReportData._id}
      activeReport={activeReport}
      setActiveReport={setActiveReport}
      activeStep={activeStep}
      setActiveStep={setActiveStep}
      activeLayerStep={activeLayerStep}
      setActiveLayerStep={setActiveLayerStep}
    />
  );
};

const SoilReport = (props) => {
  const {
    data: soilSection,
    activeReportStep,
    setReportStep,
    reportId,
    refreshData,
  } = props;
  // Use the first soil report if available, otherwise create a new one.
  const [activeReport, setActiveReport] = useState(soilSection?.[0]?._id ?? "new");
  const [deleteSoilId, setDeleteSoilId] = useState();

  const { data, refresh } = useFetch("/soils");

  const [newSoilInProgress, setNewSoilInProgress] = useState(false);

  const getReportItemClassName = (id) => {
    return `${
      activeReport === id ? "bg-gray-1" : "bg-gray-f2"
    } rounded-xl px-7 py-2 cursor-pointer font-medium`;
  };

  const form = useMemo(() => {
    return (
      <div>
        {soilSection?.map((item) => (
          <div
            key={`soil-form-${item._id}`}
            className={`${activeReport === item._id ? "block" : "hidden"}`}
          >
            <SoilReportForm
              {...props}
              soilReportData={item}
              soilsData={data}
              refreshSoilsData={refresh}
              activeReport={activeReport}
              setActiveReport={setActiveReport}
              setNewSoilInProgress={setNewSoilInProgress}
            />
          </div>
        ))}
        {activeReport === "new" && (
          <SoilReportForm
            key={`new-${reportId}`}
            {...props}
            soilsData={data}
            refreshSoilsData={refresh}
            activeReport={activeReport}
            setActiveReport={setActiveReport}
            setNewSoilInProgress={setNewSoilInProgress}
          />
        )}
      </div>
    );
  }, [soilSection, activeReport, data, props, refresh]);

  const handleDeleteSoil = useCallback(async () => {
    try {
      await request({
        method: "delete",
        url: `/reports/${reportId}/soils/${deleteSoilId}`,
      });
      setDeleteSoilId(null);
      refreshData();
    } catch (e) {
      toast.error(e.response?.data?.message || e.message);
    }
  }, [reportId, refreshData, deleteSoilId]);

  return (
    <div>
      <div className="flex items-center justify-between mb-5">
        <div className="flex items-center">
          <span className="text-lg font-semibold">Tract Crops:</span>
          <div className="flex items-center gap-2 ml-10 flex-wrap">
            {soilSection?.length
              ? soilSection.map((item, index) => (
                  <span
                    key={item._id}
                    className={`${getReportItemClassName(
                      item._id
                    )} flex items-center gap-3 pr-4`}
                    onClick={() => setActiveReport(item._id)}
                  >
                    {item.sample_id || index + 1}
                    <XMarkIcon
                      className="w-5 h-5 cursor-pointer"
                      onClick={(e) => {
                        e.stopPropagation();
                        setDeleteSoilId(item._id);
                      }}
                    />
                  </span>
                ))
              : null}
            <span              
              className={`${getReportItemClassName("new")} ${activeReport !== "new" ? "hidden" : ""}`}
            >
              New Soil
            </span>
          </div>
        </div>
        {/* NOTE: Removing (possibly temporarily) to avoid weird form states */}
        {/* <span
          className="cursor-pointer underline pr-4"
          onClick={() => setReportStep(activeReportStep + 1)}
        >
          Skip {">>"}
        </span> */}
      </div>
      {form}
      <ConfirmModal
        isOpen={!!deleteSoilId}
        toggle={() => {
          setDeleteSoilId(null);
        }}
        message="Are you sure you want to delete this soil report?"
        onConfirm={() => {
          handleDeleteSoil();
        }}
        warning={true}
      />
    </div>
  );
};

export default SoilReport;
