import React from "react";
import { twMerge } from "tailwind-merge";
import { Field } from "formik";

const Input = ({
  label,
  name,
  required,
  customLabel,
  labelClassName,
  inputClassName,
  className,
  textarea,
  suffix,
  secondaryLabel,
  type,
  onChange,
  skipDefaultOnChange,
  ...restProps
}) => {
  return (
    <Field name={name}>
      {({ field, form: { touched, errors }, meta }) => {
        return (
          <div>
            {customLabel ? (
              customLabel
            ) : (
              <label
                htmlFor={name}
                className={twMerge(
                  "block text-sm font-medium leading-6 text-gray-900 dark:text-white",
                  labelClassName
                )}
              >
                {label}
                {secondaryLabel && (
                  <span className="text-gray-500 ml-2">
                    {secondaryLabel}
                  </span>
                )}
              </label>
            )}
            <div className={twMerge("mt-2", className)}>
              {textarea ? (
                <textarea
                  id={name}
                  name={name}
                  required={required}
                  onChange={field.onChange}
                  onBlur={field.onBlur}
                  value={field.value}
                  rows={3}
                  className={twMerge(
                    "block w-full rounded-md border-0 py-1.5 text-gray-900",
                    "shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400",
                    "sm:text-sm sm:leading-6 dark:bg-neutral-700 dark:text-neutral-200",
                    inputClassName
                  )}
                  {...restProps} />
              ) : suffix ? (
                <div
                  className={twMerge(
                    "flex flex-row-reverse rounded-md shadow-sm ring-1 ring-inset",
                    "ring-gray-300 bg-white dark:bg-neutral-700",
                    "[&:has(input:is(:disabled))]:bg-stone-300"
                  )}
                >
                  <span className="flex select-none items-center pr-3 text-gray-500 sm:text-sm">
                    {suffix}
                  </span>
                  <input
                    id={name}
                    name={name}
                    autoComplete={name}
                    required={required}
                    onChange={(event) => {
                      onChange(event);
                      // HACK: I hate this component.
                      if (!skipDefaultOnChange) {
                        field.onChange(event);
                      }
                    }}
                    onBlur={field.onBlur}
                    value={field.value}
                    type={type}
                    className={twMerge(
                      "flex flex-1 m-[1px] ml-1 w-full border-0 py-1.5 text-gray-900 placeholder:text-gray-400",
                      "focus:ring-0 sm:text-sm sm:leading-6 dark:bg-neutral-700 dark:text-neutral-200 bg-transparent",
                      inputClassName
                    )}
                    {...restProps} />
                </div>
              ) : (
                <input
                  id={name}
                  name={name}
                  autoComplete={name}
                  required={required}
                  onChange={(event) => {
                    onChange(event);
                    // HACK: I hate this component.
                    if (!skipDefaultOnChange) {
                      field.onChange(event);
                    }
                  }}
                  onBlur={field.onBlur}
                  value={field.value}
                  type={type}
                  className={twMerge(
                    "block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset",
                    "ring-gray-300 placeholder:text-gray-400 sm:text-sm sm:leading-6 disabled:bg-gray-f2",
                    "dark:disabled:bg-neutral-600 dark:bg-neutral-700 dark:text-neutral-200",
                    inputClassName,
                    errors[name] ? "ring-red-500" : ""
                  )}
                  {...restProps} />
              )}
              {/* FIXME: This is making the error show up twice for Nitrogen Applied (direct_nitrogen) */}
              {meta?.touched && errors[name] ? (
                <div className="text-xs text-red-600 mt-1">{errors[name]}</div>
              ) : null}
            </div>
          </div>
        );
      }}
    </Field>
  );
};

export default Input;
