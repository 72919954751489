/* eslint-disable react-hooks/exhaustive-deps */
import React, {
  useCallback,
  useEffect,
  useMemo,
  useRef,
  useState,
} from "react";
import { steps, applicationConfig } from "../crop/configs";
import request from "../../utils/fetch";
import toast from "react-hot-toast";
import { getCropSchema } from "../../utils/validation";
import { useFormik } from "formik";
import CropReportFormContent from "./CropReportFormContent";
import { XMarkIcon } from "@heroicons/react/20/solid";
import ConfirmModal from "../common/ConfirmModal";

const formatData = (values, totalAcres, numberApp) => {
  return Object.entries(values).reduce((result, [key, value]) => {
    if (key.includes("Percent_")) {
      result[key.replace("Percent_", "")] = parseFloat(
        ((value * totalAcres) / 100).toFixed(2)
      );
    } else if (key.includes(applicationConfig.prefix)) {
      const [, index, property] = key.split("-");
      const parsedIndex = parseInt(index);
      if (parsedIndex <= numberApp) {
        const nitrateKeyData = "Nitrogen_Applications";
        if (!result[nitrateKeyData]) {
          result[nitrateKeyData] = [];
        }
        if (!result[nitrateKeyData][parsedIndex - 1]) {
          result[nitrateKeyData][parsedIndex - 1] = {};
        }
        let valueToSave = value;
        const configs = applicationConfig;
        (configs.fields || []).forEach((field) => {
          if (field.key === property) {
            if (field.number) {
              valueToSave = parseFloat(value) || 0;
            } else if (field.date) {
              valueToSave = new Date(value).toISOString();
            }
          }
        });
        result[nitrateKeyData][parsedIndex - 1][property] = valueToSave;
      }
    } else if (key.includes("manureApplications")) {
      const [, manureDataKey] = key.split("-");
      if (!result.Manure_Applications) {
        result["Manure_Applications"] = [{}];
      }
      let valueToSave = value;
      if (!isNaN(value)) {
        valueToSave = parseFloat(value);
      } else if (manureDataKey === "date_applied") {
        valueToSave = new Date(value).toISOString();
      }
      result["Manure_Applications"][0][manureDataKey] = valueToSave;
    } else {
      let valueToSave = value;
      steps.forEach((step) => {
        (step.fields || []).forEach((field) => {
          if (field.key === key) {
            if (field.number) {
              valueToSave = parseFloat(value);
            } else if (field.date) {
              valueToSave = new Date(value).toISOString();
            }
          }
        });
      });
      result[key] = valueToSave;
    }
    if (result.Sprinkler_Acres === null || isNaN(result.Sprinkler_Acres)) {
      result["Sprinkler_Acres"] = 0;
    } else if (result.Flood_Acres === null || isNaN(result.Flood_Acres)) {
      result["Flood_Acres"] = 0;
    } else if (
      result.Other_Irr_Acres === null ||
      isNaN(result.Other_Irr_Acres)
    ) {
      result["Other_Irr_Acres"] = 0;
    }
    return result;
  }, {});
};

const getInitialValues = (data) => {
  return Object.entries(data).reduce((result, [key, value]) => {
    if (key === "Nitrogen_Applications") {
      (value || []).forEach((item, index) => {
        Object.entries(item).forEach(([itemKey, itemValue]) => {
          result[`${applicationConfig.prefix}-${index + 1}-${itemKey}`] =
            itemValue;
          if (itemKey === "n_percent" && itemValue) {
            result[`${applicationConfig.prefix}-${index + 1}-advanced`] = true;
          }
        });
      });
    } else if (key === "Manure_Applications") {
      Object.entries(value?.[0] || {}).forEach(([itemKey, itemValue]) => {
        result[`manureApplications-${itemKey}`] = itemValue;
      });
    } else {
      result[key] = value;
    }
    return result;
  }, {});
};

const CropReportForm = React.forwardRef(
  (
    {
      tractAcres,
      totalTractAcres,
      totalFilledAcres,
      reportId,
      refreshData,
      initialValues,
      activeReport,
      setActiveReport,
      prevCropManure,
      setReportStep,
      activeReportStep,
    },
    ref
  ) => {
    const [isLoading, setLoading] = useState(false);
    const [activeStep, setActiveStep] = useState(0);
    const [error, setError] = useState();
    const [numberApp, setNumberApp] = useState(1);
    const [numberInhibitor, setNumberInhibitor] = useState(1);
    const currentStep = steps[activeStep];

    useEffect(() => {
      if (initialValues?.Nitrogen_Applications?.length) {
        setNumberApp(initialValues?.Nitrogen_Applications?.length);
      }
    }, [initialValues?.Nitrogen_Applications?.length]);

    function nextStepWithoutValidation(e) {
      if (activeStep === 0) {
        setActiveStep(3);
      } else if (activeStep === 4) {
        setReportStep(activeReportStep + 1);
      } else {
        setActiveStep(4);
      }
    }

    function nextStep(e) {
      e?.preventDefault();
      let isValid = true;
      if (activeStep === 0) {
        for (let i = 0; i < 3; i++) {
          const step = steps[i];
          if (step.validation) {
            const stepValid = step.validation(
              formik,
              tractAcres,
              numberApp,
              numberInhibitor
            );
            if (stepValid !== true) {
              isValid = stepValid;
              setError(isValid);
              return false;
            }
          }
        }
      } else if (currentStep.validation) {
        isValid = currentStep.validation(
          formik,
          tractAcres,
          numberApp,
          numberInhibitor
        );
      }

      if (isValid === true) {
        setError(null);
        return true;
      } else {
        setError(isValid);
        return false;
      }
    }

    const previousStep = (e) => {
      e.preventDefault();
      if (activeStep === 3) {
        setActiveStep(0);
      } else if (activeStep === 0) {
        setReportStep(activeReportStep - 1);
      } else {
        setActiveStep(activeStep - 1);
      }
      setError(null);
    };

    const handleDeleteNitrogen = useCallback(
      async (id) => {
        try {
          setLoading(true);
          await request({
            method: "delete",
            url: `/reports/${reportId}/crops/${activeReport}/nitrogen/${id}`,
          });
          await refreshData();
          toast.success("Successfully deleted crop nitrogen");
        } catch (e) {
          toast.error(e.response?.data?.message || e.message);
        } finally {
          setLoading(false);
        }
      },
      [activeReport, refreshData, reportId]
    );

    const handleSubmitManure = useCallback(async (reportId, payload) => {
      try {
        if (!payload._id) {
          return
        }
        const manureApps = payload.Manure_Applications || [];
        for (let i = 0; i < manureApps.length; i++) {
          const manurePayload = manureApps[i];
          await request({
            method: manurePayload._id ? "patch" : "post",
            url: `/reports/${reportId}/crops/${payload._id}/manure${
              manurePayload._id ? `/${manurePayload._id}` : ""
            }`,
            data: manurePayload,
          });
        }
      } catch (e) {
        throw new Error(e.response?.data?.message || e.message);
      }
    }, []);
    
    const handleSubmit = useCallback(
      async (values, { resetForm }) => {
        try {
          if (!nextStep()) {
            return;
          }
          setLoading(true);
          const payload = formatData(values, tractAcres, numberApp);
          let result = null;
          if (activeStep === 3) {
            const removeNitrogens = (initialValues?.Nitrogen_Applications || []).filter(
              (initialItem) =>
                !payload.Nitrogen_Applications.some(
                  (payloadItem) => payloadItem._id === initialItem._id
                )
            );
          
            for (let i = 0; i < removeNitrogens.length; i++) {
              await request({
                method: "delete",
                url: `/reports/${reportId}/crops/${payload._id}/nitrogen/${removeNitrogens[i]._id}`,
              });
            }
          
            for (let i = 0; i < payload.Nitrogen_Applications.length; i++) {
              const nitrogenPayload = payload.Nitrogen_Applications[i];
              await request({
                method: nitrogenPayload._id ? "patch" : "post",
                url: `/reports/${reportId}/crops/${payload._id}/nitrogen${
                  nitrogenPayload._id ? `/${nitrogenPayload._id}` : ""
                }`,
                data: {
                  ...nitrogenPayload,
                  direct_nitrogen: nitrogenPayload.direct_nitrogen || 0,
                  advanced: undefined,
                },
              });
            }
          } else {
            result = await request({
              method: initialValues?._id ? "patch" : "post",
              url: `/reports/${reportId}/crops${
                initialValues?._id ? `/${initialValues._id}` : ""
              }`,
              data: { ...payload, Nitrogen_Applications: undefined },
            });
          }

          await handleSubmitManure(reportId, payload);
          // if (!payload._id) {
          await refreshData();
          // }
          // if(activeStep === 0) {
          resetForm();
          // }
          setLoading(null);
          // nextStepWithoutValidation();
          // resetForm();
          // setActiveStep(0);
          // setNumberApp(1);
          // setNumberInhibitor(1);
          toast.success("Successfully updated crop report");
          if (activeReport === "new" && result) {
            // Focus the crop that was just submitted so we don't make a "new" one.
            setActiveReport(result);
          }
        } catch (e) {
          toast.error(e.response?.data?.message || e.message);
        } finally {
          setLoading(false);
        }
      },
      [
        activeStep,
        initialValues?.Nitrogen_Applications,
        nextStep,
        refreshData,
        reportId,
        activeReport,
        setActiveReport,
        tractAcres,
      ]
    );

    const formik = useFormik({
      initialValues: initialValues._id
        ? getInitialValues(initialValues)
        : initialValues,
      enableReinitialize: true,
      validationSchema: getCropSchema(tractAcres),
      onSubmit: handleSubmit,
    });

    React.useImperativeHandle(ref, () => ({
      submitForm: () => formik.submitForm(),
    }));

    return (
      <CropReportFormContent
        formik={formik}
        isLoading={isLoading}
        tractAcres={tractAcres}
        totalTractAcres={totalTractAcres}
        totalFilledAcres={totalFilledAcres}
        activeStep={activeStep}
        setActiveStep={setActiveStep}
        error={error}
        nextStep={nextStepWithoutValidation}
        previousStep={previousStep}
        currentStep={currentStep}
        numberApp={numberApp}
        setNumberApp={setNumberApp}
        numberInhibitor={numberInhibitor}
        setNumberInhibitor={setNumberInhibitor}
        prevCropManure={prevCropManure}
        isNew={activeReport === "new"}
        addNew={() => setActiveReport("new")}
        handleDeleteNitrogen={handleDeleteNitrogen}
      />
    );
  }
);

const CropReport = ({
  tractAcres,
  tractId,
  reportId,
  data,
  refreshData,
  setReportStep,
  activeReportStep,
  reportYear,
}) => {
  // Use the first crop if available, otherwise create a new one.
  const [activeReport, setActiveReport] = useState(data?.[0]?._id ?? "new");
  const [deleteCropId, setDeleteCropId] = useState();

  const formRef = useRef({});

  const getReportItemClassName = (id) => {
    return `${
      activeReport === id ? "bg-gray-1" : "bg-gray-f2"
    } rounded-xl px-7 py-2 cursor-pointer font-medium`;
  };

  const getCropAcres = (item) => {
    return (
      (item.Sprinkler_Acres || 0) +
      (item.Flood_Acres || 0) +
      (item.Other_Irr_Acres || 0)
    );
  };

  const consumedAcres = useMemo(() => {
    return (data || []).reduce((acc, item) => acc + getCropAcres(item), 0);
  }, [data]);

  const availableAcres = useMemo(() => {
    return tractAcres - consumedAcres;
  }, [tractAcres, data]);

  const handleAddNewCrop = () => {
    if (availableAcres > 0) {
      setActiveReport("new");
    } else {
      toast.error("All Tract Acres Accounted For");
    }
  };

  useEffect(() => {
    if (availableAcres <= 0 && activeReport === "new") {
      setActiveReport(data?.[0]?._id);
    }
  }, [availableAcres, activeReport, data]);

  const form = useMemo(() => {
    return (
      <div>
        {data?.length
          ? data.map((item, index) => (
              <div
                key={`crop_form_${item._id}`}
                className={`${activeReport === item._id ? "block" : "hidden"}`}
              >
                <CropReportForm
                  ref={(ref) => {
                    // if (!formRef.current) formRef.current = {};
                    formRef.current[item._id] = ref;
                  }}
                  totalTractAcres={tractAcres}
                  totalFilledAcres={consumedAcres}
                  // FIXME: All of the `availableAcres` logic feels super hacky. Let's clean it up.
                  tractAcres={
                    (availableAcres > 0 ? availableAcres : 0) + getCropAcres(item)
                  }
                  reportId={reportId}
                  refreshData={refreshData}
                  initialValues={item}
                  activeReport={activeReport}
                  setActiveReport={setActiveReport}
                  activeReportStep={activeReportStep}
                  setReportStep={setReportStep}
                  prevCropManure={
                    index - 1 >= 0
                      ? data[index - 1]?.Manure_Applications?.[0]
                      : null
                  }
                />
              </div>
            ))
          : null}
        <div className={`${activeReport === "new" ? "block" : "hidden"}`}>
          <CropReportForm
            key={`new-${data?.length}`}
            ref={(ref) => {
              // if (!formRef.current) formRef.current = {};
              formRef.current["new"] = ref;
            }}
            tractAcres={availableAcres > 0 ? availableAcres : 0}
            reportId={reportId}
            refreshData={refreshData}
            activeReport={activeReport}
            setActiveReport={setActiveReport}
            activeReportStep={activeReportStep}
            setReportStep={setReportStep}
            prevCropManure={
              data?.length
                ? data[data.length - 1]?.Manure_Applications?.[0]
                : null
            }
            initialValues={{
              Year: reportYear || new Date().getFullYear(),
              Crop_Yield_Units: "bushels / ac",
            }}
          />
        </div>
      </div>
    );
  }, [
    data,
    activeReport,
    availableAcres,
    reportId,
    refreshData,
    activeReportStep,
    setReportStep,
    reportYear,
  ]);

  const handleSelectCrop = (id) => {
    formRef.current[activeReport]?.submitForm();
    setActiveReport(id);
  };

  const handleDeleteCrop = useCallback(async () => {
    try {
      await request({
        method: "delete",
        url: `/reports/${reportId}/crops/${deleteCropId}`,
      });
      setDeleteCropId(null);
      refreshData();
    } catch (e) {
      toast.error(e.response?.data?.message || e.message);
    }
  }, [reportId, refreshData, deleteCropId]);

  return (
    <div>
      <div className="flex items-center justify-between mb-5">
        <div className="flex items-center">
          <span className="text-lg font-semibold">Tract Crops:</span>
          <div className="flex items-center gap-2 ml-10 flex-wrap">
            {data?.map((item) => (
              <span
                key={item._id}
                className={`${getReportItemClassName(
                  item._id
                )} flex items-center gap-3 pr-4`}
                onClick={() => handleSelectCrop(item._id)}
              >
                {item.Crop_Planted}
                <XMarkIcon
                  className="w-5 h-5 cursor-pointer"
                  onClick={(e) => {
                    e.stopPropagation();
                    setDeleteCropId(item._id);
                  }}
                />
              </span>
            ))}
            <span
              className={getReportItemClassName("new")}
              onClick={handleAddNewCrop}
            >
              + New Crop
            </span>
          </div>
        </div>
        {/* NOTE: Removing (possibly temporarily) to avoid weird form states */}
        {/* <span
          className="cursor-pointer underline pr-4"
          onClick={() => setReportStep(activeReportStep + 1)}
        >
          Skip {">>"}
        </span> */}
      </div>
      {form}
      <ConfirmModal
        isOpen={!!deleteCropId}
        toggle={() => {
          setDeleteCropId(null);
        }}
        message="Are you sure you want to delete this crop report?"
        onConfirm={() => {
          handleDeleteCrop();
        }}
        warning={true}
      />
    </div>
  );
};

export default CropReport;
