import React from "react";
import Toggle from "../common/Toggle";
import Button from "../common/Button";
import Input from "../common/form/Input";
import { MinusCircleIcon } from "@heroicons/react/24/solid";

const SoilLayer = ({ formik, numberLayer, setNumberLayer, error }) => {
  const tableHeaderClass = "flex-1 text-center py-2 text-zinc-600 uppercase";
  const tableRowClass = "flex-1 flex items-center justify-center p-2";
  const inputClass = "shadow-none disabled:bg-white text-center";

  const handleAddLayer = (e) => {
    e.preventDefault();
    if (numberLayer < 3) {
      setNumberLayer(numberLayer + 1);
    }
  };

  const borderColor =
    error && error.includes("soil layer info")
      ? "border-red-600"
      : "border-gray-300";

  const handleDeleteRow = (index) => {
    const newValues = { ...formik.values };

    for (let i = index; i < numberLayer; i++) {
      const currentLayerPrefix = `soil_layer_${i}`;
      const nextLayerPrefix = `soil_layer_${i + 1}`;

      Object.keys(newValues).forEach((key) => {
        if (key.startsWith(nextLayerPrefix)) {
          const newKey = key.replace(nextLayerPrefix, currentLayerPrefix);
          newValues[newKey] = newValues[key];
          delete newValues[key];
        }
      });
    }

    Object.keys(newValues).forEach((key) => {
      if (key.startsWith(`soil_layer_${numberLayer}`)) {
        delete newValues[key];
      }
    });
    formik.setValues(newValues, true);
    setNumberLayer(numberLayer - 1);
  };

  return (
    <div className="col-span-2">
      <div className="font-bold">Soil Layer Information:</div>
      <div>
        Add the number of soil layers that are in your test. If you have only
        one composite layer please check composite box and use only first layer.
      </div>
      <div className="px-5 mt-5">
        <div className="flex items-center justify-between">
          <Toggle
            key="composite_sample"
            value={formik.values.composite_sample}
            label="Composite Sample"
            onChange={(value) =>
              formik.setFieldValue("composite_sample", value)
            }
          />
          <Button
            onClick={handleAddLayer}
            disabled={formik.values.composite_sample || numberLayer === 3}
          >
            Add Layer
          </Button>
        </div>
        <div className={`rounded-lg mt-5 shadow border ${borderColor}`}>
          <div className="flex bg-gray-100 rounded-t-lg">
            <div className={tableHeaderClass}>Layer Number</div>
            <div className={tableHeaderClass}>Top Depth (IN)</div>
            <div className={tableHeaderClass}>Bottom Depth (IN)</div>
            <div className="w-10" />
          </div>
          {Array(numberLayer)
            .fill("")
            .map((item, index) => (
              <div key={index} className={`flex border-t ${borderColor}`}>
                <div className={tableRowClass}>
                  <Input
                    inputClassName={`${inputClass} ring-0`}
                    disabled={true}
                    value={index + 1}
                  />
                </div>
                <div className={tableRowClass}>
                  <Input
                    name={`soil_layer_${index + 1}_top_depth`}
                    type="number"
                    inputClassName={`${inputClass} ${index === 0 ? "disabled:bg-gray-200" : ""}`}
                    disabled={index === 0}
                    value={index === 0 ? 0 : formik.values[`soil_layer_${index + 1}_top_depth`]}
                    onChange={(e) => {
                      if (index === 0) return;
                      formik.setFieldValue(
                        `soil_layer_${index + 1}_top_depth`,
                        e.target.value && e.target.value !== ""
                          ? parseFloat(e.target.value)
                          : undefined
                      );
                    }}
                  />
                </div>
                <div className={tableRowClass}>
                  <Input
                    name={`soil_layer_${index + 1}_bottom_depth`}
                    type="number"
                    inputClassName={inputClass}
                    value={
                      formik.values[`soil_layer_${index + 1}_bottom_depth`]
                    }
                    onChange={(e) =>
                      formik.setFieldValue(
                        `soil_layer_${index + 1}_bottom_depth`,
                        e.target.value && e.target.value !== ""
                          ? parseFloat(e.target.value)
                          : undefined
                      )
                    }
                  />
                </div>
                <div className={`w-10 flex items-center justify-center pl-0`}>
                  {index > 0 ? (
                    <MinusCircleIcon
                      className="w-5 h-5 cursor-pointer text-red-600"
                      onClick={() => handleDeleteRow(index)}
                    />
                  ) : (
                    <div className="w-10" />
                  )}
                </div>
              </div>
            ))}
        </div>
      </div>
    </div>
  );
};

export default SoilLayer;
