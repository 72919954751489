export const steps = [
  { 
    name: "Soil Sample Location",
    description: "Confirm Map Location",
    title: "Confirm Soil Sample Location",
    type: "map",
  },
  {  
    name: "Sample and Soil Information",
    description: "Name, Date, Lab, Acres, Texture, and Layers",
    title: "Add Soil Sample and Layers",
    type: "form",
    fields: [
      {
        key: "sample_id",
        label: "Sample Name or Id",
      },
      {
        key: "sample_date",
        label: "Date Sample Taken",
        date: true,
      },
      {
        key: "lab_name",
        label: "Lab Name",
        dropdown: true,
        options: [
          "AgLab Express in South Dakota",
          "American Ag Lab out of McCook",
          "Deveron in Clear Lake, Iowa",
          "Midwest Labs",
          "UNL Soil Lab",
          "Ward Labs",
        ],
      },
      {
        key: "lab_date",
        label: "Date On Lab Report",
        date: true,
      },
      {
        key: "note",
        label: "Sample Note",
        textarea: true,
        elementClassName: "md:col-span-2",
      },
      {
        key: "acres_represented",
        label: "Acres Represented",
        number: true,
      },
      {
        key: "soil_texture",
        label: "Soil Texture",
        dropdown: true,
        options: ["Sand", "Sandy Loam", "Loam", "Clay Loam", "Clay"],
      },
      {
        key: "soil_layer",
        soilLayer: true,
      },
    ],
    validation: (values, numberLayer) => {
      if (!values.sample_id || !values.sample_date) {
        return "Sample name and date are required.";
      }
  
      for (let i = 1; i <= numberLayer; i++) {
        if (
          (i !== 1 && !values.hasOwnProperty(`soil_layer_${i}_top_depth`)) ||
          !values.hasOwnProperty(`soil_layer_${i}_bottom_depth`)
        ) {
          return "Please enter all soil layer info";
        }
      }
  
      return true;
    },
  },
  { 
    name: "Layer Test Results",
    description: "PH, Organic Matter, N, P, K Values",
    title: "Add Soil Test Results",
    type: "test_result",
    fields: [
      [
        {
          key: "ph",
          label: "Soil PH",
          number: true,
          elementClassName: "col-start-1 col-span-2",
        },
        {
          key: "soluble_salts",
          label: "Soluble Salts",
          number: true,
          elementClassName: "col-start-5 col-span-2",
          suffix: "mmho / cm",
        },
        {
          key: "organic_matter",
          label: "Organic Matter",
          number: true,
          elementClassName: "col-start-9 col-span-2",
          suffix: "%",
        },
      ],
      [
        {
          key: "nitrate",
          label: "Nitrate",
          number: true,
          elementClassName: "col-start-1 col-span-2",
          suffix: "ppm N",
          required: true,
        },
        {
          key: "phosphorus",
          label: "Phosphorus",
          number: true,
          elementClassName: "col-start-5 col-span-2",
          suffix: "ppm P",
        },
        {
          key: "potassium",
          label: "K (Potassium)",
          number: true,
          elementClassName: "col-start-9 col-span-2",
          suffix: "ppm",
        },
      ],
      [
        {
          key: "calcium",
          label: "Ca (Calcium)",
          number: true,
          elementClassName: "col-start-1 col-span-2",
          suffix: "ppm",
        },
        {
          key: "magnesium",
          label: "Mg (Magnesium)",
          number: true,
          elementClassName: "col-start-5 col-span-2",
          suffix: "ppm",
        },
        {
          key: "sodium",
          label: "Na (Sodium)",
          number: true,
          elementClassName: "col-start-9 col-span-2",
          suffix: "ppm",
        },
      ],
      [
        {
          key: "sulfate",
          label: "Sulfate",
          number: true,
          elementClassName: "col-start-4 col-span-2",
          suffix: "ppm",
        },
        {
          key: "cec",
          label: "Sum of Cations",
          number: true,
          elementClassName: "col-start-7 col-span-2",
          suffix: "me / 100g",
        },
      ],
    ],
  },
  { type: "add_soil", className: "p-4" },
  // {
  //   name: "Summary",
  //   description: "Data Entered and Upload Result",
  //   title: "Confirm Soil Sample",
  //   type: "submit",
  // },
];
