import React, { useState } from "react";
import Modal from "./Modal";
import { capitalize } from "../../utils/helpers";
import Button from "./Button";
import request from "../../utils/fetch";
import toast from "react-hot-toast";
import { Alias } from "../../utils/validation";
import GenericForm from "./form/GenericForm";

const AliasModalContent = ({ isLoading, handleSubmit, initialValue }) => {
  return (
    <GenericForm
      onSubmit={handleSubmit}
      validationSchema={Alias}
      initialValues={initialValue}
      fields={[
        {
          key: "alias",
          label: "Alias",
          elementClassName: "col-span-2",
        },
      ]}
    >
      <div className="flex justify-end col-span-2">
        <Button type="submit" className="w-fit mt-8" loading={isLoading}>
          Save
        </Button>
      </div>
    </GenericForm>
  );
};

const AliasModal = ({ open, setIsOpen, table, tableId, refreshData, alias }) => {
  const [isLoading, setLoading] = useState(false);

  const handleSubmit = async (values, { resetForm }) => {
    try {
      setLoading(true);
      await request({
        method: "patch",
        url: `/${table}/${tableId}/setAlias`,
        data: values,
      });
      if (refreshData) {
        refreshData();
      }
      toast.success("Successfully updated alias");
      setLoading(null);
      setIsOpen(false);
      resetForm();
    } catch (e) {
      toast.error(e.response?.data?.message || e.message);
    } finally {
      setLoading(false);
    }
  };

  return (
    <Modal
      small
      isOpen={open}
      close={() => {
        setIsOpen(false);
      }}
      title={`Set ${capitalize(table)} Alias`}
      Content={AliasModalContent}
      contentProps={{
        isLoading,
        handleSubmit,
        initialValue: { alias },
      }}
    />
  );
};

export default AliasModal;
