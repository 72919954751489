import * as Yup from "yup";

const VALIDATION_ERROR_MSGs = {
  REQUIRED: "Field is required",
  INTEGER_NUMBER: "Invalid integer number.",
  NUMBER: "Invalid number.",
  DATE: "Invalid date.",
  PERCENT: "The percent value must be between 0~100",
  PHONE: "Invalid phone number.",
  POSITIVE_NUMBER: "Invalid positive number.",
  EMAIL: "Invalid email.",
  INVALID_YEAR: "Must be in the year format - YYYY",
};

const validatePasswordPattern = (value) => {
  return /[a-z]/.test(value) && /[A-Z]/.test(value) && /[0-9]/.test(value);
};

export const SignInSchema = Yup.object({
  email: Yup.string()
    .email()
    .required(VALIDATION_ERROR_MSGs.REQUIRED)
    .typeError(VALIDATION_ERROR_MSGs.EMAIL),
  password: Yup.string().required(VALIDATION_ERROR_MSGs.REQUIRED),
});

export const ForgotPasswordSchema = Yup.object({
  email: Yup.string()
    .email()
    .required(VALIDATION_ERROR_MSGs.REQUIRED)
    .typeError(VALIDATION_ERROR_MSGs.EMAIL),
});

export const NewPasswordSchema = Yup.object({
  email: Yup.string()
    .email()
    .required(VALIDATION_ERROR_MSGs.REQUIRED)
    .typeError(VALIDATION_ERROR_MSGs.EMAIL),
  code: Yup.string().required(VALIDATION_ERROR_MSGs.REQUIRED),
  password: Yup.string()
    .required(VALIDATION_ERROR_MSGs.REQUIRED)
    .min(8, "The password has to be at least 8 characters long.")
    .test(
      "password-pattern",
      "The password has to be contain at least one lower case letter, one upper case letter and a number.",
      (value) => validatePasswordPattern(value)
    ),
  confirm: Yup.string()
    .required(VALIDATION_ERROR_MSGs.REQUIRED)
    .oneOf([Yup.ref("password"), null], "Passwords must match"),
});

export const CompleteRegistrationSchema = Yup.object({
  password: Yup.string()
    .required(VALIDATION_ERROR_MSGs.REQUIRED)
    .min(8, "The password has to be at least 8 characters long.")
    .test(
      "password-pattern",
      "The password has to be contain at least one lower case letter, one upper case letter and a number.",
      (value) => validatePasswordPattern(value)
    ),
  confirm: Yup.string()
    .required(VALIDATION_ERROR_MSGs.REQUIRED)
    .oneOf([Yup.ref("password"), null], "Passwords must match"),
});

export const getCropSchema = (tractAcres) =>
  Yup.object({
    Crop_Planted: Yup.string().required(VALIDATION_ERROR_MSGs.REQUIRED),
    Year: Yup.number(VALIDATION_ERROR_MSGs.INVALID_YEAR)
      .required(VALIDATION_ERROR_MSGs.INVALID_YEAR)
      .test(
        "len",
        VALIDATION_ERROR_MSGs.INVALID_YEAR,
        (val) => val && val.toString().length === 4
      ),
    Crop_Yield: Yup.number(VALIDATION_ERROR_MSGs.NUMBER).required(
      VALIDATION_ERROR_MSGs.REQUIRED
    ),
    Yield_Goal: Yup.number()
      .positive(VALIDATION_ERROR_MSGs.POSITIVE)
      .max(400, VALIDATION_ERROR_MSGs.MAX)
      .when("Crop_Planted", {
        is: "Corn",
        then: (schema) => schema.required(VALIDATION_ERROR_MSGs.REQUIRED),
        otherwise: (schema) => schema.notRequired(),
      }),
    Inhibitor_Name: Yup.string().max(
      24,
      "Name of Nitrogen Inhibitor must be less than 24 characters long"
    ),
    Sprinkler_Acres: Yup.number().max(
      tractAcres,
      `Value must be smaller than total acres`
    ),
    Flood_Acres: Yup.number().max(
      tractAcres,
      `Value must be smaller than total acres`
    ),
    Other_Irr_Acres: Yup.number().max(
      tractAcres,
      `Value must be smaller than total acres`
    ),
  });

export const SoilSchema = Yup.object({
  sample_id: Yup.string().required(VALIDATION_ERROR_MSGs.REQUIRED),
  sample_date: Yup.string().required(VALIDATION_ERROR_MSGs.REQUIRED),
});

export const getMeterReadSchema = (factor) =>
  Yup.object({
    reading:
      factor === 1
        ? Yup.number().required(VALIDATION_ERROR_MSGs.REQUIRED)
        : Yup.number()
            .integer(VALIDATION_ERROR_MSGs.INTEGER_NUMBER)
            .required(VALIDATION_ERROR_MSGs.REQUIRED),
  });

export const Alias = Yup.object({
  alias: Yup.string().required(VALIDATION_ERROR_MSGs.REQUIRED),
});

export const generateNitrogenApplicationSchema = (count) => {
  const schema = {};
  const nitrogenAppPrefix = "Nitrogen_Applications";

  for (let i = 1; i <= count; i++) {
    schema[`${nitrogenAppPrefix}-${i}-date_applied`] = Yup.date()
      .required("Date applied is required")
      .typeError("Invalid date");

    schema[`${nitrogenAppPrefix}-${i}-emergent_type`] = Yup.string().required(
      "Emergence type is required"
    );

    schema[`${nitrogenAppPrefix}-${i}-inhibitor_type`] = Yup.lazy(
      (value, ctx) => {
        const formValues = ctx?.parent || {};
        const inhibitorUsed =
          formValues[`${nitrogenAppPrefix}-${i}-inhibitor_used`];
        return inhibitorUsed
          ? Yup.string().required("Inhibitor type is required")
          : Yup.mixed().notRequired();
      }
    );
    schema[`${nitrogenAppPrefix}-${i}-n_density`] = Yup.lazy((value, ctx) => {
      const formValues = ctx?.parent || {};
      const advanced = formValues[`${nitrogenAppPrefix}-${i}-advanced`];
      return advanced
        ? Yup.number()
            .required("N Density is required")
            .min(1, "N Density must be greater than zero")
        : Yup.mixed().notRequired();
    });
    schema[`${nitrogenAppPrefix}-${i}-n_percent`] = Yup.lazy((value, ctx) => {
      const formValues = ctx?.parent || {};
      const advanced = formValues[`${nitrogenAppPrefix}-${i}-advanced`];
      return advanced
        ? Yup.number()
            .required("N Percent is required")
            .min(0.01, "N Percent must be greater than zero")
        : Yup.mixed().notRequired();
    });
    schema[`${nitrogenAppPrefix}-${i}-amount`] = Yup.lazy((value, ctx) => {
      const formValues = ctx?.parent || {};
      const advanced = formValues[`${nitrogenAppPrefix}-${i}-advanced`];
      return advanced
        ? Yup.number()
            .required("Amount is required")
            .min(0.01, "Amount must be greater than zero")
        : Yup.mixed().notRequired();
    });
    schema[`${nitrogenAppPrefix}-${i}-units`] = Yup.lazy((value, ctx) => {
      const formValues = ctx?.parent || {};
      const advanced = formValues[`${nitrogenAppPrefix}-${i}-advanced`];
      return advanced
        ? Yup.string()
            .required("Units are required")
            .min(0.01, "Units must be greater than zero")
        : Yup.mixed().notRequired();
    });
  }

  return schema;
};

export const MeterReportSchema = Yup.object({
  Date: Yup.string().required(VALIDATION_ERROR_MSGs.REQUIRED),
});
