import React, { useCallback, useState } from "react";
import Button from "../common/Button";
import Input from "../common/form/Input";
import DatePicker from "../common/form/DatePicker";
import Select from "../common/form/Select";
import { MinusCircleIcon } from "@heroicons/react/24/solid";
import { ChevronDownIcon, ChevronUpIcon } from "@heroicons/react/24/outline";
import Toggle from "../common/Toggle";

const handleAdvancedToggle = (formik, config, index, key, value) => {
  formik.setFieldValue(key, value);

  if (key === `${config.prefix}-${index + 1}-advanced` && value === true) {
    const nitrogenAppliedKey = `${config.prefix}-${index + 1}-direct_nitrogen`;
    formik.setFieldValue(nitrogenAppliedKey, "");
  } else if (
    key === `${config.prefix}-${index + 1}-advanced` &&
    value === false
  ) {
    const n = `${config.prefix}-${index + 1}-n_percent`;
    const p = `${config.prefix}-${index + 1}-p_percent`;
    const k = `${config.prefix}-${index + 1}-k_percent`;
    const d = `${config.prefix}-${index + 1}-n_density`;
    const t = `${config.prefix}-${index + 1}-type`;
    const a = `${config.prefix}-${index + 1}-amount`;
    const u = `${config.prefix}-${index + 1}-units`;

    const setNull = [n, p, k, d, t, a, u];

    setNull.forEach((fieldKey) => {
      if (formik.values[fieldKey] !== undefined) {
        formik.setFieldValue(fieldKey, "");
      }
    });
  }

  if (
    key === `${config.prefix}-${index + 1}-inhibitor_used` &&
    value === false
  ) {
    const inhibitorAppliedKey = `${config.prefix}-${index + 1}-inhibitor_type`;
    formik.setFieldValue(inhibitorAppliedKey, "");
  }
};

const handlePercentInput = (formik, config, index, key, value) => {
  const n = `${config.prefix}-${index + 1}-n_percent`;
  const p = `${config.prefix}-${index + 1}-p_percent`;
  const k = `${config.prefix}-${index + 1}-k_percent`;

  if (!isNaN(value)) {
    if (key === n || key === p || key === k) {
      formik.setFieldValue(key, value / 100);
    } else {
      formik.setFieldValue(key, value);
    }
  } else {
    formik.setFieldValue(key, value);
  }
};

const percentValueToShow = (config, index, key, value) => {
  const n = `${config.prefix}-${index + 1}-n_percent`;
  const p = `${config.prefix}-${index + 1}-p_percent`;
  const k = `${config.prefix}-${index + 1}-k_percent`;

  if (value === null || value === undefined) {
    return "";
  }

  if (key === n || key === p || key === k) {
    // NOTE: This prevents a floating point issue where 0.61 might display as 60.999999997. The
    //       tradeoff is that it limits the input to 2 decimal places, but that's fine for now.
    return Math.round(value * 10000) / 100;
  } else {
    return value;
  }
};

const ApplicationItem = ({
  index,
  handleDeleteRow,
  config,
  formik,
  error,
  expandable = true,
}) => {
  const [expand, setExpand] = useState(true);

  const renderFormField = (
    field,
    formik,
    config,
    index,
    handleAdvancedToggle,
    handlePercentInput
  ) => {
    const {
      key,
      date,
      dropdown,
      options,
      label,
      bool,
      suffix,
      secondaryLabel,
      type,
    } = field;
    const formKey = `${config.prefix}-${index + 1}-${key}`;

    if (date) {
      return (
        <DatePicker
          name={formKey}
          inputClassName="h-[38px]"
          value={formik.values[formKey]}
          label={label}
          onChange={(e) => formik.setFieldValue(formKey, e.target.value)}
        />
      );
    }

    if (dropdown) {
      return (
        <Select
          extraOptions={options}
          onChange={(option) => formik.setFieldValue(formKey, option)}
          label={label}
          value={formik.values[formKey]}
          className="w-full"
        />
      );
    }

    if (bool) {
      return (
        <Toggle
          value={formik.values[formKey]}
          label={label}
          onChange={(value) =>
            handleAdvancedToggle(formik, config, index, formKey, value)
          }
        />
      );
    }

    return (
      <Input
        name={formKey}
        inputClassName="text-center px-0 h-[38px]"
        value={percentValueToShow(
          config,
          index,
          formKey,
          formik.values[formKey]
        )}
        label={label}
        suffix={suffix}
        secondaryLabel={secondaryLabel}
        type={type}
        disabled={
          key === "direct_nitrogen" &&
          formik.values[`${config.prefix}-${index + 1}-advanced`] === true
        }
        onChange={(e) => {
          handlePercentInput(formik, config, index, formKey, e.target.value);
          formik.setFieldTouched(formKey);
        }}
        skipDefaultOnChange={formKey.includes('_percent')}
      />
    );
  };

  return (
    <div className={`mb-4 ${expandable ? "" : "pt-4 border-t"}`}>
      <div
        className={`flex items-center justify-between px-4 py-2 cursor-pointer ${
          expandable ? "bg-gray-f2" : ""
        } rounded-md ${
          error?.inValidIndex && error.inValidIndex.indexOf(index + 1) !== -1
            ? "border rounded-md border-red-600"
            : ""
        }`}
        onClick={() => {
          if (expandable) {
            setExpand(!expand);
          }
        }}
      >
        <span>Nitrogen Application {index + 1}</span>
        <div className="flex items-center gap-x-3">
          {index > 0 && (
            <MinusCircleIcon
              className="w-5 h-5 cursor-pointer text-red-600"
              onClick={() => handleDeleteRow(index)}
            />
          )}
          {expandable ? (
            <>
              {expand ? (
                <ChevronDownIcon className="w-5 h-5 inline-block" />
              ) : (
                <ChevronUpIcon className="w-5 h-5 inline-block" />
              )}
            </>
          ) : null}
        </div>
      </div>
      {expand && (
        <div className="grid grid-cols-1 md:grid-cols-3 gap-3 p-4">
          {config.fields.map((field) => {
            const formKey = `${config.prefix}-${index + 1}-${field.key}`;
            const shouldRender =
              !field.condition ||
              (field.condition === "inhibitor_used" &&
                formik.values[
                  `${config.prefix}-${index + 1}-inhibitor_used`
                ]) ||
              (field.condition === "advanced" &&
                formik.values[`${config.prefix}-${index + 1}-advanced`]);

            if (!shouldRender) return null;

            return (
              <div key={formKey} className="col-span-1">
                {renderFormField(
                  field,
                  formik,
                  config,
                  index,
                  handleAdvancedToggle,
                  handlePercentInput
                )}
                {field.comment && (
                  <div className="text-sm text-gray-500">{field.comment}</div>
                )}
                {formik.errors[formKey] && (
                  <div className="text-xs text-red-600 mt-1 w-full">
                    {formik.errors[formKey]}
                  </div>
                )}
              </div>
            );
          })}
        </div>
      )}
    </div>
  );
};

const CropNitrateApplicationNew = (props) => {
  const { formik, config, numberApp, setNumberApp, expandable } = props;

  const handleDeleteRow = useCallback(
    (index) => {
      for (let i = index; i < numberApp; i++) {
        config.fields.forEach(({ key }) => {
          const currentKey = `${config.prefix}-${i + 1}-${key}`;
          const nextKey = `${config.prefix}-${i + 2}-${key}`;

          if (formik.values.hasOwnProperty(nextKey)) {
            formik.setFieldValue(currentKey, formik.values[nextKey]);
          } else if (formik.values.hasOwnProperty(currentKey)) {
            formik.setFieldValue(currentKey, undefined);
          }
        });
      }

      config.fields.forEach(({ key }) => {
        const lastKey = `${config.prefix}-${numberApp}-${key}`;
        delete formik.values[lastKey];
      });

      delete formik.values[`${config.prefix}-${numberApp}-_id`];
      setNumberApp(numberApp - 1);
    },
    [config.fields, config.prefix, formik, numberApp, setNumberApp]
  );

  const handleAddApplication = useCallback(
    (e) => {
      e.preventDefault();
      setNumberApp((prev) => prev + 1);
    },
    [setNumberApp]
  );

  const addButton = (
    <Button onClick={handleAddApplication}>
      {config.prefix === "nitrateApp"
        ? "Add Application Event"
        : "Add Application"}
    </Button>
  );

  return (
    <div>
      {!expandable && (
        <div className="flex items-center justify-between pb-5">
          <div className="text-lg">Nitrogen Applications</div>
          {addButton}
        </div>
      )}
      <div className={expandable ? "overflow-y-auto nitrateAppForm" : ""}>
        {Array(numberApp)
          .fill("")
          .map((item, index) => (
            <ApplicationItem
              key={`nitrate_app_${index}`}
              index={index}
              handleDeleteRow={handleDeleteRow}
              expandable={expandable}
              {...props}
            />
          ))}
      </div>
      {expandable && <div className="flex justify-end mt-3">{addButton}</div>}
    </div>
  );
};

export default CropNitrateApplicationNew;
